<template>
  <div class="app orders py-5">
    <div class="row" v-if="this.orders.pallet_histories">
      <div class="col">
        <div class="row align-items-center mb-3">
          <div class="col-6 col-md-2">
            <div class="form-group mb-0">
              <div class="select-box compact">
                <label for="year">{{ $t('year-label') }}</label>
                <Select2 id="year" v-model="year" :options="years" :settings="{ minimumResultsForSearch: -1 }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div v-show="!this.ordersLoaded">
          <Loader />
        </div>
        <div class="tab-content" v-show="this.ordersLoaded">
          <div class="tab-content-inner p-4">
            <div class="panel-content text-center mt-4 pb-5" v-if="!this.orders.pallet_histories">
              <h2>{{ $t('no-orders-label') }}</h2>
            </div>
            <div class="row mb-3">
              <div class="col-auto border-right pr-4">
                <div class="text-muted">{{ $t('outstanding-pallets-label') }}</div>
                <div class="font-size-32">{{ orders.outstanding }}</div>
              </div>
              <div class="col-auto border-right px-4" v-show="orders.pallet_setting === 'dealer-label'">
                <div class="text-muted">{{ $t('unpaid-label') }}</div>
                <div class="font-size-32">{{ orders.unpaid }}</div>
              </div>
              <div class="col-auto pl-4" v-show="orders.pallet_setting === 'customer-label'">
                <div class="text-muted">{{ $t('credits-available-label') }}</div>
                <div class="font-size-32">{{ orders.credits_available }}</div>
              </div>
            </div>
            <div class="panel-content pb-2" v-if="this.orders">
              <div>
                <div>
                  <div class="table-orders">
                    <table id="pallets" class="table table-compact table-striped table-responsive-md" cellspacing="0"
                      width="100%">
                      <thead>
                        <tr>
                          <th class="th pl-md-3">{{ $t('date-label') }}</th>
                          <th class="th">{{ $t('type-label') }}</th>
                          <th class="th">{{ $t('order-label') }}</th>
                          <th class="th">{{ $t('taken-label') }}</th>
                          <th class="th">{{ $t('return-label') }}</th>
                          <th class="th">{{ $t('invoiced-label') }}</th>
                          <th class="th" v-show="orders.pallet_setting === 'customer-label'">{{ $t('credits-used-label')
                          }}</th>
                          <th class="th" v-show="orders.pallet_setting === 'customer-label'">{{
                            $t('credits-received-label') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in orders.pallet_histories" :key="item.id"
                          v-show="(year === 'all' || createYear(item.date) === year)">
                          {{ addYearAndMonth(item.date) }}
                          <td class="col-time" v-bind:data-th="$t('date-label')">
                            <template v-if="item.type === 'return-label' || item.type === 'cash-out-label'">
                              <div class="text-decoration-none">
                                <div class="d-inline-block">
                                  <p class="text-dark font-weight-bold mb-0 font-size-13 pr-2">
                                    {{ createDate(item.date) }}
                                  </p>
                                </div>
                              </div>
                            </template>

                            <!-- Otherwise, show a router-link -->
                            <template v-else>
                              <router-link :to="'/order/' + item.order_id">
                                <div class="text-decoration-none">
                                  <div class="d-inline-block">
                                    <p class="text-dark font-weight-bold mb-0 font-size-13 pr-2">
                                      {{ createDate(item.date) }}
                                    </p>
                                  </div>
                                </div>
                              </router-link>
                            </template>
                          </td>
                          <td class="col-type" v-bind:data-th="$t('type-label')">
                            <template v-if="item.type === 'return-label' || item.type === 'cash-out-label'">
                              <div class="text-decoration-none">
                                <div class="d-inline-block">
                                  {{ $t(item.type) }}
                                </div>
                              </div>
                            </template>

                            <template v-else>
                              <router-link :to="'/order/' + item.order_id">
                                <div class="text-decoration-none">
                                  <div class="d-inline-block">
                                    {{ $t(item.type) }}
                                  </div>
                                </div>
                              </router-link>
                            </template>
                          </td>

                          <td class="rolls-col" v-bind:data-th="$t('order-label')">
                            <template v-if="item.type === 'return-label' || item.type === 'cash-out-label'">
                              <div class="text-decoration-none">
                                <div class="d-inline-block">
                                  {{ item.order_id }}
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              <router-link :to="'/order/' + item.order_id">
                                <div class="text-decoration-none">
                                  <div class="d-inline-block">
                                    {{ item.order_id }}
                                  </div>
                                </div>
                              </router-link>
                            </template>
                          </td>

                          <td class="rolls-col" v-bind:data-th="$t('taken-label')">
                            <div class="text-decoration-none">
                              <div class="d-inline-block text-dark font-weight-bold">
                                {{ item.taken }}
                              </div>
                            </div>
                          </td>

                          <td class="rolls-col" v-bind:data-th="$t('returned-label')">
                            <div class="text-decoration-none">
                              <div class="d-inline-block text-dark font-weight-bold">
                                {{ item.returned }}
                              </div>
                            </div>
                          </td>


                          <td class="rolls-col" v-bind:data-th="$t('invoiced-label')">
                            <div class="text-decoration-none">
                              <div class="d-inline-block text-dark font-weight-bold">
                                {{ item.invoiced }}
                              </div>
                            </div>
                          </td>

                          <td class="rolls-col" v-bind:data-th="$t('credits-used-label')"
                            v-show="orders.pallet_setting === 'customer-label'">
                            <div class="text-decoration-none">
                              <div class="d-inline-block text-dark font-weight-bold">
                                {{ item.credits_used }}
                              </div>
                            </div>
                          </td>

                          <td class="rolls-col" v-bind:data-th="$t('credits-received-label')"
                            v-show="orders.pallet_setting === 'customer-label'">
                            <div class="text-decoration-none">
                              <div class="d-inline-block text-dark font-weight-bold">
                                {{ item.credits_received }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Loader from '../components/Modules/Loader'
import { logout } from "@/main";
const axios = require('axios').default;


export default {
  name: 'PalletHistory',
  data() {
    return {
      orders: {},
      ordersLoaded: false,
      year: this.createYear(new Date()),
      years: [
        { id: 'all', text: this.$i18n.t('all-label') }
      ]
    }
  },
  components: {
    Loader
  },
  methods: {

    addYearAndMonth(date) {
      const formattedYear = this.createYear(date);

      if (!this.years.filter(function (e) { return e.id === formattedYear; }).length) {
        this.years.push({ id: formattedYear, text: formattedYear })
      }

    },

    createYear(date) {
      return moment(date).format('YYYY')
    },
    createMonth(date) {
      return moment(date).format('MM')
    },
    createMonthString(month) {
      switch (month) {
        case '01':
          return this.$i18n.t('january-label');

        case '02':
          return this.$i18n.t('february-label');

        case '03':
          return this.$i18n.t('march-label');

        case '04':
          return this.$i18n.t('april-label');

        case '05':
          return this.$i18n.t('may-label');

        case '06':
          return this.$i18n.t('june-label');

        case '07':
          return this.$i18n.t('july-label');

        case '08':
          return this.$i18n.t('august-label');

        case '09':
          return this.$i18n.t('september-label');

        case '10':
          return this.$i18n.t('october-label');

        case '11':
          return this.$i18n.t('november-label');

        case '12':
          return this.$i18n.t('december-label');
      }
    },
    getPalletHistory() {
      const self = this
      axios.get(process.env.VUE_APP_API_URL + 'client/pallets', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        console.log(response);
        self.orders = response.data.data
        self.ordersLoaded = true
      }).catch(function (error) {
        if (error.response.status === 401) {
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },

    createDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    createTime(date) {
      return moment(date).format('HH:mm')
    }
  },
  beforeMount() {
    this.getPalletHistory();
  }
}
</script>
